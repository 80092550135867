import p1 from "./img/Project1.png";
import p2 from "./img/Project2.png";
import p3 from "./img/Project3.png";
import p4 from "./img/Project4.png";
import p5 from "./img/Project5.jpg";

export const project = [
  {
    id: 1,
    img: p1,
    link: "http://medonlab.afifraihan.com",
    desc: "Medical Check Up Registration Website.",
    tools: ["HTML", "CSS", "JS", "CodeIgniter", "Bootstrap"],
  },
  {
    id: 2,
    img: p2,
    link: "https://x4d0.github.io/Website-Rekrutasi-Asprak",
    desc: "Online Test Website for Practicum Assistant Recruitment.",
    tools: ["HTML", "CSS", "JS", "Laravel", "Bootstrap"],
  },
  {
    id: 3,
    img: p3,
    link: "http://kukerhut.id",
    desc: "Catalogue for KukerHut (Cookies & Cakes) Products.",
    tools: ["HTML", "CSS", "JS", "CodeIgniter", "Bootstrap"],
  },
  {
    id: 4,
    img: p4,
    link: "https://x4d0.github.io/balanjaan",
    desc: "A Website to write down your Grocery List.",
    tools: ["HTML", "CSS", "ReactJS"],
  },
  {
    id: 5,
    img: p5,
    link: "https://x4d0.github.io/AdzanToday",
    desc: "Indonesia Adzan Time.",
    tools: ["HTML", "CSS", "ReactJS", "Axios (API)", "MaterialUI"],
  },
];
